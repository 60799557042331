



















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import { AppModule } from '@/store/modules/app'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import { createImportFile, preprocessImport } from '@/api/consoleApi/imports'
import { ImportStrategyEnum, ImportStrategyFileDelimiterEnum, ImportStrategyFileQuoteEnum } from '@/api/consoleApi/dto/imports.dto'
import { get } from 'lodash'
import { EditorView } from 'codemirror'
import {
    keymap,
    placeholder,
    lineNumbers,
    highlightSpecialChars,
    drawSelection,
    dropCursor,
    rectangularSelection,
    crosshairCursor,
} from '@codemirror/view'
import { foldGutter, indentOnInput, syntaxHighlighting, defaultHighlightStyle, bracketMatching, foldKeymap } from '@codemirror/language'
import {
    EditorState,
    Compartment,
} from '@codemirror/state'
import {
    history,
    defaultKeymap,
    historyKeymap,
} from '@codemirror/commands'
import {
    highlightSelectionMatches,
    searchKeymap,
} from '@codemirror/search'
import { closeBrackets, autocompletion, closeBracketsKeymap, completionKeymap } from '@codemirror/autocomplete'
import { lintKeymap } from '@codemirror/lint'
import VsLoader from '@/components/VsLoader/Index.vue'
import axios from 'axios'

@Component({
    name: 'ImportCopyPaste',
    components: {
        VsContainer,
        MyAccordion,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({ default: null }) list!: any
    private delimiter: ImportStrategyFileDelimiterEnum = ImportStrategyFileDelimiterEnum.tab
    private loading = false
    private skipFirstRow = true
    private file: any = ''
    private inputFileNameValue = ''
    private fileName = ''
    private dirty = false
    private content = ''
    private lines = 0
    private editor: any = null
    private maxLines = 500
    private preprocessData = {}
    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    mounted () {
        this.startCodemirror()
    }

    private startCodemirror () {
        const textarea = this.$refs.codeTextArea
        const tabSize = new Compartment()
        this.editor = new EditorView({
            state: EditorState.create({
                doc: textarea.value,
                extensions: [
                    lineNumbers(),
                    // highlightActiveLineGutter(),
                    highlightSpecialChars(),
                    history(),
                    foldGutter(),
                    drawSelection(),
                    dropCursor(),
                    EditorState.allowMultipleSelections.of(true),
                    indentOnInput(),
                    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
                    bracketMatching(),
                    closeBrackets(),
                    autocompletion(),
                    rectangularSelection(),
                    crosshairCursor(),
                    // highlightActiveLine(),
                    highlightSelectionMatches(),
                    keymap.of([
                        ...closeBracketsKeymap,
                        ...defaultKeymap,
                        ...searchKeymap,
                        ...historyKeymap,
                        ...foldKeymap,
                        ...completionKeymap,
                        ...lintKeymap,
                    ]),
                    placeholder(`1  Email    Nome    Telefono
2  mario@example.com   Mario Rossi    +393281122333
3  giulia@example.com   Giulia Verdi    +393281122333`),
                    tabSize.of(EditorState.tabSize.of(16)),
                    EditorView.updateListener.of((v: any) => {
                        if (v.docChanged) {
                            this.dirty = true
                            this.lines = v.state.doc.lines
                            this.content = v.state.doc.toString()
                        }
                    }),
                ],
            }),
        })
        textarea.parentNode.insertBefore(this.editor.dom, textarea)
        textarea.style.display = 'none'
        if (textarea.form) {
            textarea.form.addEventListener('submit', () => {
                textarea.value = this.editor.state.doc.toString()
            })
        }
    }

    async uploadFile () {
        this.dirty = true
        if (!this.content) {
            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.copyPaste.uploadErrorNotPossible'),
                message: this.$t('lists.importContacts.copyPaste.uploadErrorNoContent'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        if (this.lines > this.maxLines) {
            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.copyPaste.uploadErrorNotPossible'),
                message: this.$t('lists.importContacts.copyPaste.uploadErrorLineLimit'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.loading = true
        await this.createAndPreprocessImport()
        this.loading = false
    }

    private loadingText (progressEvent?: any) {
        const loaded = progressEvent ? progressEvent.loaded : 0
        const total = progressEvent ? progressEvent.total : 0
        return `${this.$options?.filters?.formatBytes(loaded)} di ${this.$options?.filters?.formatBytes(total)}`
    }

    async createAndPreprocessImport () {
        const importId = await this.createImport()
        if (importId) {
            try {
                const preprocess = await preprocessImport(importId)
                this.preprocessData = preprocess.data.data
                this.$emit('submit', this.preprocessData)
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
                let heading = this.$t('lists.importContacts.fromFile.uploadError')
                if (axios.isAxiosError(e)) {
                    if (e.response?.status === 400) {
                        heading = this.$t('lists.importContacts.fromFile.errors.' + e.response.data.code)
                    }
                }
                this.$root.$vsToast({
                    heading,
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
        }
    }

    private async createImport () {
        const toast = this.$root.$vsToast({
            heading: this.$t('lists.importContacts.fromFile.uploading'),
            aspect: VsToastAspectEnum.neutral,
        })
        try {
            const importData: any = this.prepareDataImport()

            const resp = await createImportFile(
                importData,
                {
                    onUploadProgress: (progressEvent: any) => {
                        toast.message = this.loadingText(progressEvent)
                        if (progressEvent.loaded === progressEvent.total) {
                            toast.dismiss(toast.id)
                        }
                    },
                },
            )
            return resp.data
        } catch (e) {
            toast.dismiss(toast.id)
            if (!get(e, 'response', '') && get(e, 'message', '') === 'Network Error') {
                this.$root.$vsToast({
                    heading: this.$t('lists.importContacts.fromFile.uploadErrorFileChanged'),
                    message: this.$t('lists.importContacts.fromFile.uploadErrorFileChangedMessage'),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 5000,
                })
                return
            }
            const err = get(e, 'response.data.message.file', [])
            let message = this.$t('lists.importContacts.fromFile.uploadErrorGeneric')
            for (const el of err) {
                const size = el.match(/\d/g) || []
                if (size.length > 0 && el.startsWith('The file may not be greater than')) {
                    message = `${this.$t('lists.importContacts.fromFile.uploadErrorSize')} ${this.$options?.filters?.formatKbToMb(size.join(''))}`
                }
            }
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 413) {
                    message = this.$t('lists.importContacts.fromFile.uploadErrorServerSize')
                }
            }

            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.fromFile.uploadError'),
                aspect: VsToastAspectEnum.alert,
                message,
                timeout: 3000,
            })

            console.log(e)
        }
    }

    private prepareDataImport () {
        const csvString = this.content
        const blob = new Blob([csvString], { type: 'text/csv' })
        const data = new FormData()
        data.append('entity_id', this.isSuppressionImport ? '0' : this.listId)
        data.append('entity_type', this.isSuppressionImport ? 'suppression' : 'recipient')
        data.append('import_strategy', ImportStrategyEnum.file)
        data.append('import_strategy_settings[file]', blob)
        data.append('import_strategy_settings[quote]', ImportStrategyFileQuoteEnum.double)
        data.append('import_strategy_settings[delimiter]', this.delimiter)
        data.append('import_strategy_settings[skip_first_row]', this.skipFirstRow ? '1' : '0')
        return data
    }
}
