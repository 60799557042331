var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',{attrs:{"variant":"narrow","loading":_vm.loading}},[_c('div',{staticClass:"vs-flex flex-nowrap vs-flex-col"},[_c('div',{staticClass:"vs-h700 vs-mb-2"},[_vm._v(_vm._s(_vm.$t('lists.importContacts.copyPaste.title')))]),_c('i18n',{staticClass:"vs-body-large",attrs:{"tag":"div","path":"lists.importContacts.copyPaste.subtitle"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lists.importContacts.copyPaste.subBold')))])]),_c('i18n',{staticClass:"vs-body-large",attrs:{"tag":"div","path":"lists.importContacts.copyPaste.subtitle2"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lists.importContacts.copyPaste.subBold2')))])]),(_vm.is4Dem)?_c('div',{staticClass:"vs-body-large"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.copyPaste.university'))+" "),_c('VsButton',{attrs:{"variant":"link","size":"large","url":(_vm.universityUrl + "/article/701-come-importare-i-contatti-e-leggere-lo-storico-importazioni#2"),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.copyPaste.universityLink'))+" ")]),_vm._v(". ")],1):_vm._e()],1),_c('div',{staticClass:"vs-mt-10"},[_c('div',{staticClass:"isResizable",class:{
                'vs-border-alert-500': (_vm.dirty && !_vm.content) || _vm.lines > _vm.maxLines,
                'vs-hide-gutters': !_vm.content,
            }},[_c('textarea',{ref:"codeTextArea"})]),_c('div',{staticClass:"vs-flex vs-items-center vs-mt-2",class:{
                'vs-text-alert-500': (_vm.dirty && !_vm.content) || _vm.lines > _vm.maxLines,
            }},[_c('VsCaption',{staticClass:"vs-flex-auto",class:{
                    'vs-text-alert-500': (_vm.dirty && !_vm.content) || _vm.lines > _vm.maxLines,
                }},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.copyPaste.copyCaption'))+_vm._s(_vm._f("formatNumber")(_vm.maxLines))+" ")]),_c('div',{staticClass:"vs-body-medium vs-flex-none"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.content ? _vm.lines : 0))+"/"+_vm._s(_vm._f("formatNumber")(_vm.maxLines))+" ")])],1)]),_c('div',{staticClass:"vs-mt-10"},[_c('VsCheckbox',{attrs:{"size":"large","labelHidden":true,"text":_vm.$t('lists.importContacts.copyPaste.fileFirstLineText'),"caption":_vm.$t('lists.importContacts.copyPaste.fileFirstLineText')},model:{value:(_vm.skipFirstRow),callback:function ($$v) {_vm.skipFirstRow=$$v},expression:"skipFirstRow"}})],1),_c('div',{staticClass:"vs-mt-10"},[_c('VsButton',{attrs:{"size":"large","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.uploadFile}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.copyPaste.upload'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }